import React, { useRef } from "react"

import { Layout } from "@components/layout"
import {
  Hero,
  HeaderSlider,
  PartnersSlider2018,
  Program2018,
  GoogleMap,
  OldContentLinks,
} from "@components/standard"

const IndexPage2018 = ({ pageContext, location }) => {
  const { page, productsWithDetails, posts } = pageContext
  return (
    <Layout {...page}>
      <HeaderSlider />
      <OldContentLinks />
      <Hero />
      <Program2018 />
      <PartnersSlider2018 />
      <GoogleMap />
    </Layout>
  )
}

export default IndexPage2018
